var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _vm.showList
        ? _c("TablePage", {
            key: _vm.tableKey,
            ref: "tablePage",
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options,
              callback: function ($$v) {
                _vm.options = $$v
              },
              expression: "options",
            },
          })
        : _c("TablePage", {
            key: _vm.tableKey,
            ref: "tablePageDetail",
            staticClass: "detail",
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.optionsDetail,
              callback: function ($$v) {
                _vm.optionsDetail = $$v
              },
              expression: "optionsDetail",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }