var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "wrap",
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.goodsOptions,
            rules: _vm.rules,
            "label-width": "130px",
          },
        },
        [
          _c("topOperatingButton", {
            ref: "topOperatingButton",
            attrs: {
              showAudit: _vm.isShowAudit,
              isStopBtn: false,
              isSubmitAddBtn: false,
              isExamineBtn: true,
              disabled: _vm.disableOperate,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.submitForm(true)
              },
              auditBill: _vm.auditForm,
              getQuit: _vm.getQuit,
            },
          }),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "基本信息", id: "basicInformation" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-w marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: true,
                            placeholder: "活动编号",
                            size: "mini",
                          },
                          model: {
                            value: _vm.goodsOptions.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.goodsOptions, "billNo", $$v)
                            },
                            expression: "goodsOptions.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动名称", prop: "billName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disableOperate,
                            placeholder: "活动名称",
                            maxlength: "40",
                            size: "mini",
                          },
                          model: {
                            value: _vm.goodsOptions.billName,
                            callback: function ($$v) {
                              _vm.$set(_vm.goodsOptions, "billName", $$v)
                            },
                            expression: "goodsOptions.billName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动日期", prop: "datetime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            disabled: _vm.disableOperate,
                            "picker-options": _vm.forbiddenTime,
                            size: "mini",
                          },
                          model: {
                            value: _vm.goodsOptions.datetime,
                            callback: function ($$v) {
                              _vm.$set(_vm.goodsOptions, "datetime", $$v)
                            },
                            expression: "goodsOptions.datetime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案说明", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            type: "textarea",
                            placeholder: "方案说明长度介于 1 和 200 字符之间",
                            maxlength: "200",
                            autosize: { minRows: 2, maxRows: 2 },
                            disabled: _vm.disableOperate,
                          },
                          model: {
                            value: _vm.goodsOptions.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.goodsOptions, "billRemark", $$v)
                            },
                            expression: "goodsOptions.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f " },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "支付方式", prop: "type" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            attrs: { disabled: _vm.disableOperate },
                            model: {
                              value: _vm.goodsOptions.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsOptions, "type", $$v)
                              },
                              expression: "goodsOptions.type",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "微信支付", name: "type" },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "会员卡支付", name: "type" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f " },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "配送方式", prop: "delivery" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            attrs: { disabled: _vm.disableOperate },
                            model: {
                              value: _vm.goodsOptions.delivery,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsOptions, "delivery", $$v)
                              },
                              expression: "goodsOptions.delivery",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "门店自提", name: "type" },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "同城配送", name: "type" },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "快递配送", name: "type" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "活动商品", id: "basicInformation" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: " marT10 mar20" },
                  [
                    _c(
                      "div",
                      { staticClass: "mt20" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.disableOperate,
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openGoodsDialog")
                              },
                            },
                          },
                          [_vm._v("选择商品")]
                        ),
                      ],
                      1
                    ),
                    _c("EditTable", {
                      staticStyle: { width: "100%" },
                      attrs: { options: _vm.goodsOptions },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "更多配置" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "giveRule" }, [
                  _c(
                    "div",
                    { staticClass: "multiplyTitle" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "适用门店", required: true } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: _vm.disableOperate, label: 0 },
                              model: {
                                value: _vm.goodsOptions.shopRangeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.goodsOptions,
                                    "shopRangeType",
                                    $$v
                                  )
                                },
                                expression: "goodsOptions.shopRangeType",
                              },
                            },
                            [_vm._v("全部门店")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: _vm.disableOperate, label: 1 },
                              model: {
                                value: _vm.goodsOptions.shopRangeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.goodsOptions,
                                    "shopRangeType",
                                    $$v
                                  )
                                },
                                expression: "goodsOptions.shopRangeType",
                              },
                            },
                            [_vm._v("全部门店+排除")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: _vm.disableOperate, label: 2 },
                              model: {
                                value: _vm.goodsOptions.shopRangeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.goodsOptions,
                                    "shopRangeType",
                                    $$v
                                  )
                                },
                                expression: "goodsOptions.shopRangeType",
                              },
                            },
                            [_vm._v("指定门店")]
                          ),
                          [1, 2].includes(_vm.goodsOptions.shopRangeType)
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        disabled: _vm.disableOperate,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEvent(
                                            "openShopDialog"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择门店")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "mt10" },
                                    [
                                      _c("EditTable", {
                                        attrs: { options: _vm.shopOptions },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: {
                            label: "首刀砍价比例(%)",
                            prop: "bargainFirstRate",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "fillRuleinputMoney",
                            staticStyle: { width: "200px" },
                            attrs: {
                              disabled: _vm.disableOperate,
                              oninput:
                                "if (value > 90) value = 90; value=value.replace(/^0|[^0-9]/g,'')",
                              max: 90,
                            },
                            model: {
                              value: _vm.goodsOptions.bargainFirstRate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.goodsOptions,
                                  "bargainFirstRate",
                                  $$v
                                )
                              },
                              expression: "goodsOptions.bargainFirstRate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "text",
                          staticStyle: { "margin-bottom": "15px" },
                        },
                        [
                          _vm._v(
                            "此处为买家发起砍价时自己首刀砍价比例,建议设的更高一点提高买家砍价兴趣例: 商品售价为￥100.首刀比例为90%.则发起砍价时买家可以自己砍￥90"
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "砍价有效期",
                            prop: "bargainLimitHour",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "fillRuleinputMoney",
                              staticStyle: { width: "200px" },
                              attrs: {
                                disabled: _vm.disableOperate,
                                size: "mini",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.limitInputlength(
                                    _vm.goodsOptions.bargainLimitHour,
                                    _vm.goodsOptions,
                                    "bargainLimitHour",
                                    true,
                                    0,
                                    false,
                                    9999
                                  )
                                },
                              },
                              model: {
                                value: _vm.goodsOptions.bargainLimitHour,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.goodsOptions,
                                    "bargainLimitHour",
                                    $$v
                                  )
                                },
                                expression: "goodsOptions.bargainLimitHour",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "append" },
                                [_c("el-button", [_vm._v("小时")])],
                                1
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticStyle: {
                                color: "black",
                                "margin-left": "10px",
                              },
                              attrs: {
                                content: "用户发起砍价到砍价截止的时间",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "砍价人数",
                            prop: "bargainPeopleNum",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "fillRuleinputMoney",
                            staticStyle: { width: "200px" },
                            attrs: {
                              disabled: _vm.disableOperate,
                              oninput:
                                "if (value > 10) value = 10; value=value.replace(/^0|[^0-9]/g,'')",
                            },
                            model: {
                              value: _vm.goodsOptions.bargainPeopleNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.goodsOptions,
                                  "bargainPeopleNum",
                                  $$v
                                )
                              },
                              expression: "goodsOptions.bargainPeopleNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "每人帮砍金额",
                            prop: "bargainNoPeopleType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: _vm.disableOperate, label: 1 },
                              model: {
                                value: _vm.goodsOptions.bargainNoPeopleType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.goodsOptions,
                                    "bargainNoPeopleType",
                                    $$v
                                  )
                                },
                                expression: "goodsOptions.bargainNoPeopleType",
                              },
                            },
                            [_vm._v("随机")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: _vm.disableOperate, label: 2 },
                              model: {
                                value: _vm.goodsOptions.bargainNoPeopleType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.goodsOptions,
                                    "bargainNoPeopleType",
                                    $$v
                                  )
                                },
                                expression: "goodsOptions.bargainNoPeopleType",
                              },
                            },
                            [_vm._v("固定")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "购买设置", prop: "bargainRuleType" },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: _vm.disableOperate, label: 1 },
                              model: {
                                value: _vm.goodsOptions.bargainRuleType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.goodsOptions,
                                    "bargainRuleType",
                                    $$v
                                  )
                                },
                                expression: "goodsOptions.bargainRuleType",
                              },
                            },
                            [_vm._v("砍到任意金额购买")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: _vm.disableOperate, label: 2 },
                              model: {
                                value: _vm.goodsOptions.bargainRuleType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.goodsOptions,
                                    "bargainRuleType",
                                    $$v
                                  )
                                },
                                expression: "goodsOptions.bargainRuleType",
                              },
                            },
                            [_vm._v("砍到底价购买")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "发起砍价次数",
                            prop: "bargainLimitTimes",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "fillRuleinputMoney",
                            staticStyle: { width: "200px" },
                            attrs: {
                              disabled: _vm.disableOperate,
                              size: "mini",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.limitInputlength(
                                  _vm.goodsOptions.bargainLimitTimes,
                                  _vm.goodsOptions,
                                  "bargainLimitTimes",
                                  true,
                                  2,
                                  false,
                                  9999
                                )
                              },
                            },
                            model: {
                              value: _vm.goodsOptions.bargainLimitTimes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.goodsOptions,
                                  "bargainLimitTimes",
                                  $$v
                                )
                              },
                              expression: "goodsOptions.bargainLimitTimes",
                            },
                          }),
                          _c(
                            "el-tooltip",
                            {
                              staticStyle: {
                                color: "black",
                                "margin-left": "10px",
                              },
                              attrs: {
                                content:
                                  "每个会员账号参与该活动能发起的砍价次数",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "帮砍次数",
                            prop: "bargainDayHelpTimes",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "fillRuleinputMoney",
                            staticStyle: { width: "200px" },
                            attrs: {
                              disabled: _vm.disableOperate,
                              size: "mini",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.limitInputlength(
                                  _vm.goodsOptions.bargainDayHelpTimes,
                                  _vm.goodsOptions,
                                  "bargainDayHelpTimes",
                                  true,
                                  2,
                                  false,
                                  9999
                                )
                              },
                            },
                            model: {
                              value: _vm.goodsOptions.bargainDayHelpTimes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.goodsOptions,
                                  "bargainDayHelpTimes",
                                  $$v
                                )
                              },
                              expression: "goodsOptions.bargainDayHelpTimes",
                            },
                          }),
                          _c(
                            "el-tooltip",
                            {
                              staticStyle: {
                                color: "black",
                                "margin-left": "10px",
                              },
                              attrs: {
                                content:
                                  "限制每个会员每天能参与该活动的帮忙砍价次数",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c("Dialog", {
            attrs: { options: _vm.dialogOptions },
            on: {
              "update:options": function ($event) {
                _vm.dialogOptions = $event
              },
              handleEvent: _vm.handleEvent,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }