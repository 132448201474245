<!-- 砍价 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-if="showList" v-model="options" @handleEvent="handleEvent" :key="tableKey" />
    <TablePage ref="tablePageDetail" v-else class="detail" v-model="optionsDetail" @handleEvent="handleEvent"
      :key="tableKey" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  bargainCaseList,
  bargainCaseEnable,
  bargainCaseAudit,
  bargainCaseDelete,
  bargainCaseCopy,
  getMallMarketingBargainOrderLog,
} from "@/api/O2OMall/marketing/bargain";
export default {
  name: "StockQuery",
  components: { TablePage },
  // dicts: ["bill_status"],
  dicts: ["order_status", "fetch_type", "order_type", "bill_status"],
  data() {
    return {
      tableKey: Date.now(),
      showList: true,
      openStockQuery: false,
      goodsDetailData: {},
      options: {
        uuid: "230b3a8b-8018-33a3-b5aa-665afdf4d018",
        radioSelect: true,
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        rowKey: "billId",
        check: [],
        title: "砍价",
        tableTitle: "砍价",
        search: [
          {
            label: "创建时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ queryBegTime: a, queryEndTime: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/活动编号/活动名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "活动编号" },
              { filter: "billNames", label: "活动名称" },
            ],
          },
          this.$select({
            key: "executionStatus",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "isStopState",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "bill_status",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "recon",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoRecon",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
            alertText: '确认要删除选中的活动吗？',
          },
          {
            click: "oepnDetail",
            label: "砍价记录表",
            type: "primary",
            right: true,
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        getListApi: bargainCaseList,
        columns: [
          {
            prop: "billNo",
            label: "活动编号",
            type: "link",
            click: "routerLink",
            minWidth: 155,
          },
          {
            prop: "billName",
            label: "活动名称",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "活动状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "executionStatusName",
            label: "执行状态",
            minWidth: 155,
            formatter: (v, row) => {
              let currentTime = new Date().getTime();
              let startTime = new Date(row.begTime).getTime();
              let endTime = new Date(row.endTime).getTime();
              return currentTime < startTime
                ? "未开始"
                : currentTime > endTime
                  ? "已失效"
                  : "进行中";
            },
          },
          {
            prop: "isStop",
            label: "是否停用",
            formatter: (v, row) => (row.isStop ? "禁用" : "启用"),
            minWidth: 120,
          },
          {
            prop: "begTime",
            label: "生效时间",
            width: 170,
          },
          {
            prop: "endTime",
            label: "失效时间",
            width: 170,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 120,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list: [
          {
            goodsNo: "000",
          },
        ],
      },
      optionsDetail: {
        uuid: "cdf9c4b5-e97c-a5a2-02cd-5acef8289966",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "砍价明细",
        tableTitle: "砍价明细",
        rowKey: "billId",
        defaultBody: {
          billId: "",
        },
        getListApi: getMallMarketingBargainOrderLog,
        search: [
          {
            label: "发起时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/订单编号/会员卡号/手机号",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "订单编号" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "tels", label: "手机号" },
            ],
          },
          // this.$select({ key: "isStatus",option: { seniorSearch: true, option: { multiple: true }}}),
          this.$select({
            key: "order_status",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          // this.$select({ key: "isStatus",option: { seniorSearch: true, option: { multiple: true }}}),
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
          // { type: 'button', tip: '关闭', btnType: 'primary', click: 'close' },
        ],
        buttons: [
          {
            click: "close",
            label: "关闭",
            type: "primary",
            right: true,
            // disabled: () => !this.optionsDetail?.check?.length,
          },
        ],
        columns: [
          { label: "订单编号", prop: "billNo", fixed: true, minWidth: 180 },
          { label: "发起时间", prop: "begBargainTime", minWidth: 155 },
          { label: "付款时间", prop: "orderPayTime", minWidth: 155 },
          {
            type: "dict",
            dict: "order_status",
            prop: "orderStatus",
            label: "订单状态",
            minWidth: 100,
          },

          {
            label: "砍价状态",
            prop: " bargainStatus",
            formatter: (v, row) => {
              let obj = {
                0: "正常",
                1: "砍价中",
                2: "砍价完成",
                3: "砍价取消",
              };
              return obj[row.bargainStatus];
            },
            minWidth: 100,
          },
          {
            type: "dict",
            dict: "fetch_type",
            prop: "fetchType",
            label: "配送方式",
            minWidth: 100,
          },
          { label: "配送费用", prop: "deliveryFee", minWidth: 100 },
          { label: "提货/配送门店", prop: "deliveryShopName", minWidth: 140 },
          {
            prop: "payModeName",
            label: "支付方式",
            minWidth: 100,
          },
          {
            prop: "vipNo",
            label: "会员卡号",
            align: "center",
            minWidth: 175,
          },
          {
            prop: "receiverName",
            label: "提(收)货人姓名",
            minWidth: 120,
          },
          {
            prop: "receiverPhone",
            label: "电话",
            minWidth: 110,
          },
          {
            prop: "receiverAddress",
            label: "地址",
            minWidth: 180,
            formatter: (v, row) =>
              `${row.receiverAddress || ""} ${row.receiverRegionAddress || ""}`,
          },
          { label: "商品编码", prop: "goodsNo", minWidth: 120 },
          { label: "商品名称", prop: "goodsName", minWidth: 160 },
          { label: "条码", prop: "barcode", minWidth: 120 },
          { label: "规格", prop: "goodsSpec", minWidth: 120 },
          { label: "单位", prop: "unitName", minWidth: 120 },
          { label: "数量", prop: "unitQty", minWidth: 100 },
          { label: "原价", prop: "unitOldPrice", minWidth: 100 },
          { label: "售价", prop: "unitPrice", minWidth: 100 },
          { label: "销售金额", prop: "unitMoney", minWidth: 100 },
          { label: "优惠金额", prop: "agioMoney", minWidth: 100 },
          // { label: "销售金额", prop: "saleMoney", minWidth: 100 },  //先去掉
          { label: "备注", prop: "remark", minWidth: 100 },
        ],
        list: [{}],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "close":
          this.showList = true;
          this.tableKey = Date.now();
          break;
        case "oepnDetail": //获取点击商品信息
          if (this.options.check.length == 0) return this.$message.warning("请选择活动");
          this.optionsDetail.defaultBody.billId = this.options.check[0].billId;
          this.showList = false;
          this.tableKey = Date.now();
          break;
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "BargainDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "BargainDetail",
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            const billId = selectData
              .filter((i) => i.billStatus != 2)
              .map((i) => i.billId);
            if (billId.length == 0) {
              return this.$message.warning("请选择未审核的数据");
            }
            bargainCaseAudit({ billIds: billId, auditStatus: 2 }).then((res) => {
              this.$message.success("审核成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            bargainCaseDelete(billIds).then((res) => {
              this.$message.success("删除成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            bargainCaseCopy(billIds).then((res) => {
              this.$message.success("复制成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "recon":
          {
            if (!selectData.length) return;
            const billId = selectData.map((i) => i.billId);
            if (selectData[0].billStatus != 2)
              return this.$message.warning("只能操作已审核的数据");
            bargainCaseEnable({ billIds: billId, isStop: false }).then((res) => {
              this.$message.success("启用成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "redoRecon":
          {
            if (!selectData.length) return;
            const billId = selectData.map((i) => i.billId);
            if (selectData[0].billStatus != 2)
              return this.$message.warning("只能操作已审核的数据");
            bargainCaseEnable({ billIds: billId, isStop: true }).then((res) => {
              this.$message.success("禁用成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
